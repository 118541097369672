<template>
  <div>
    <el-dialog title="LT不达标原因考核设置" :visible.sync="visible" width="800px" :close-on-click-modal="false">
      <div v-loading="defaultLoading" style="text-align: center">
        <el-transfer
          v-model="setting"
          style="text-align: left; display: inline-block"
          filterable
          :filter-method="filterMethod"
          filter-placeholder="请输入搜索内容"
          :data="data"
          :props="{
            key: 'ltSubstandardDict',
            label: 'ltSubstandardI18'
          }"
          :titles="['考核', '不考核']"
          :button-texts="['加入左侧', '加入右侧']"
        />
      </div>
      <el-divider content-position="left" class="mt-4 mb-4">修改记录</el-divider>
      <Table
        :table-data="detailTable"
        max-height="175px"
        :columns="columns"
      />
      <div class="block">
        <Paging :pager="pager" end @pagination="pagerUpdate" />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="$emit('input',false)">关闭</el-button>
        <el-button :loading="loading" type="primary" @click="handleSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { pageSubstandardConfigLog, ltSubstandardConfig, ltSubstandardConfigList } from '@/api/safestock.js'
import Table from '@/components/Table'
import Paging from '@/components/Pagination'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'

export default {
  components: { Table, Paging },
  mixins: [commodityInfoDict],

  props: {
    value: {
      type: Boolean,
      default: false
    }

  },
  data() {
    return {
      defaulNoAssessmenttList: [],
      defaultLoading: false,
      loading: false,
      pager: {
        current: 1,
        size: 10,
        total: 0
      },
      detailTable: [],
      data: [],
      setting: [],
      columns: [
        { label: '修改人', prop: 'createByName' },
        { label: '修改时间', prop: 'createTime' },
        { label: '生效时间', prop: 'effectiveTime' },
        { label: '状态', prop: 'stateI18' }
      ]
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }

  },
  watch: {
    'value'(val) {
      if (val) {
        // 查看
        this.generateData()
        this.pager.current = 1
        this.handleConfig()
        this.handleQuery()
      }
    }
  },
  created() {

  },
  mounted() {
  },
  methods: {
    isEqualArray(arr1, arr2) {
      return Array.isArray(arr1) &&
                Array.isArray(arr2) &&
                arr1.length === arr2.length &&
                JSON.stringify(arr1) === JSON.stringify(arr2)
    },

    handleSave() {
      const form = { noAssessment: [], assessment: [] }
      this.data.map(item => {
        this.setting.includes(item.ltSubstandardDict) ? form.noAssessment.push(item) : form.assessment.push(item)
      })

      if (this.isEqualArray(this.setting, this.defaulNoAssessmenttList)) return this.$emit('input', false)
      this.$confirm('该操作将重新计算生成应交准交报表数据，请确认!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.handleSubmit(form)
      }).catch(() => {})
    },
    async handleSubmit(form) {
      try {
        this.loading = true
        await ltSubstandardConfig(form)
        this.$message.success('操作成功')
        this.$emit('input', false)
      } finally {
        this.loading = false
      }
    },
    async handleConfig() {
      try {
        this.defaultLoading = true
        this.setting = []
        const { datas: { noAssessment = [] }} = await ltSubstandardConfigList()
        noAssessment?.map(item => this.setting.push(item.ltSubstandardDict))

        this.defaulNoAssessmenttList = this.setting
      } finally {
        this.defaultLoading = false
      }
    },
    filterMethod(query, item) {
      return item.ltSubstandardI18.indexOf(query) > -1
    },
    async handleQuery() {
      const { datas: { pager, records }} = await pageSubstandardConfigLog(this.pager)
      this.pager = pager
      this.detailTable = records
    },
    pagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    },
    generateData() {
      this.data = this._getAllCommodityDict('REASON_LT_SUBSTANDARD').map(item => { return { ltSubstandardI18: item.label, ltSubstandardDict: item.val } })
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-transfer-panel{
width: 260px!important;
height: 300px;
}
/deep/.el-transfer__buttons {
  width:170px!important;
  .el-button {
    width:115px;
    margin-left:0;
  }
}
</style>
