<template>
  <div id="box">
    <div :style="cellStyle">
      <el-form
        ref="queryForm"
        v-model="queryForm"
        :rules="queryRules"
        style="display:flex;"
        label-width="110px"
        label-position="right"
      >
        <div class="el-lt" style="width:85%">
          <el-row class="row-input" :class="{ 'show-row': showRow }">
            <el-col :span="8">
              <el-form-item :class="$i18n.locale" label="供应商">
                <el-select v-model="queryForm.vendorId" clearable filterable multiple :collapse-tags="true">
                  <el-option v-for="item in vendorOptions" :key="item.id" :label="item.vendorName" :value="item.id" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :class="$i18n.locale" label="区域">
                <el-select v-model="queryForm.area" clearable filterable>
                  <el-option
                    v-for="item in _getAllCommodityDict('AREA_CODE')"
                    :key="item.val"
                    :label="item.label"
                    :value="item.val"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :class="$i18n.locale" label="BG">
                <el-select v-model="queryForm.brand" clearable filterable multiple :collapse-tags="true">
                  <el-option
                    v-for="item in brandOptions"
                    :key="item.id"
                    :label="item.brandName"
                    :value="item.brandName"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :class="$i18n.locale" label="BU">
                <el-select v-model="queryForm.operatorGroup" clearable filterable multiple :collapse-tags="true">
                  <el-option
                    v-for="item in groupoptions"
                    :key="item.groupId"
                    :label="item.groupName"
                    :value="item.groupName"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item :class="$i18n.locale" label="季节">
                <el-select v-model="queryForm.season" value-key="id" multiple clearable filterable>
                  <el-option
                    v-for="item in _getAllCommodityDict('SEASON')"
                    :key="item.val"
                    :label="item.label"
                    :value="item.val"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :class="$i18n.locale" label="Category">
                <el-select v-model="queryForm.category" clearable filterable multiple :collapse-tags="true">
                  <el-option
                    v-for="item in categoryList"
                    :key="item.id"
                    :label="item.categoryName"
                    :value="item.categoryName"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :class="$i18n.locale" label="Style">
                <el-select v-model="queryForm.style" clearable filterable multiple :collapse-tags="true">
                  <el-option
                    v-for="item in styleOptions"
                    :key="item.id"
                    :label="item.styleName"
                    :value="item.styleName"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :class="$i18n.locale" label="采购员">
                <el-select v-model="queryForm.purchasePersonnel" clearable filterable multiple :collapse-tags="true">
                  <el-option v-for="(item,index) in PurchasePersonnelList" :key="index" :label="item" :value="item" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="是否首单" :class="$i18n.locale">
                <el-select v-model="queryForm.isFirst" :placeholder="$t('page.selectPlaceholder')" filterable>
                  <el-option label="是" :value="true" />
                  <el-option label="否" :value="false" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="下单日期">
                <el-date-picker
                  v-model="queryForm.value1"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="采购单号" :class="$i18n.locale">
                <el-input
                  v-model="queryForm.purchaseOrderCode"
                  placeholder="以换行符分隔"
                  type="textarea"
                  autosize
                  resize="none"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="实际交货日期">
                <el-date-picker
                  v-model="queryForm.value2"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="应交交货日期">
                <el-date-picker
                  v-model="queryForm.value3"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="预计交货日期">
                <el-date-picker
                  v-model="queryForm.value4"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                />
              </el-form-item>
            </el-col>
          </el-row>

        </div>
        <div class="el-rt" style="width:15%">
          <el-col class="row-center">
            <el-button type="primary" size="small" @click="queryClick()">{{ $t('page.search') }}</el-button>
            <el-button size="small" @click="resetClick()">{{ $t('page.reset') }}</el-button>
            <el-button type="text" size="small" @click="showRow=!showRow">
              {{ showRow ? $t('page.hide') : $t('page.expand') }}
            </el-button>
          </el-col>
        </div>
      </el-form>
    </div>

    <div :style="showRow?'margin-top: 300px;':'margin-top: 80px;'">
      <el-row class="grid-content bg-purple-dark mt-1 mb-3">
        <el-col :span="20" class="bg-purple-title">概览数据</el-col>
      </el-row>
      <div style="display:flex;">
        <div v-for="(item,index) in viewDatas" :key="index" class="view-data">
          <div>{{ item.label }}</div>
          <div style="font-weight:600;">{{ item.value }}</div>
        </div>
      </div>
      <el-row class="grid-content bg-purple-dark mt-5">
        <el-col :span="20" class="bg-purple-title">分类汇总
          <el-select v-model="category" placeholder="" @change="handleCategory">
            <el-option
              v-for="item in _getAllCommodityDict('ODTT_CATEGORY_TYPE')"
              :key="item.val"
              :label="item.label"
              :value="item.label"
            />
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-button
            class="toolbar-icon--button mr-2"
            type="text"
            icon="el-icon-download"
            style="float:left;"
            :loading="exportDownLoading"
            @click="exportDownLoad('catagory',cateParams, '应交准交分类汇总表')"
          >下载</el-button>
          <VxeColumnSettings
            v-if="zTableRef"
            ref="CategotyColumnSetting"
            :container-ref="zTableRef"
            ref-name="zTable"
            local-key="category-channel"
            class="ml-2"
            @upDate="(val) => $refs.zTable.reloadColumn(val)"
          >
            <el-button
              class="toolbar-icon--button"
              type="text"
              icon="el-icon-s-fold"
              style="float:left;"
              @click="$refs['CategotyColumnSetting'].visibleShow()"
            >展示列设置</el-button>
          </VxeColumnSettings>
        </el-col>
      </el-row>

      <vxe-table
        ref="zTable"
        class="seperate"
        :data="categoryTableData"
        border
        max-height="500px"
        show-overflow
        :loading="queryCategoryLoading"
        highlight-current-row
        align="center"
        :keyboard-config="{isArrow: true}"
        show-footer
        :footer-method="footerMethod"
      >
        <vxe-table-column label="内容" width="150" align="center">
          <vxe-table-column field="fieldName" :title="category" align="center" width="150" />
        </vxe-table-column>
        <vxe-table-column field="netCollectNumber" title="已交货数量" align="center" />
        <vxe-table-column field="otNumber" title="应交数量" align="center" />
        <vxe-table-column field="otddNumber" title="应交准交数量" align="center" />
        <vxe-table-column field="otDelayNumber" title="应交延期数量" align="center" />
        <vxe-table-column field="otd" title="OTD" align="center" />
        <vxe-table-column field="actualLt" title="实际LT" align="center" />

        <vxe-table-column field="assessLt" title="考核LT" align="center" />
      </vxe-table>
      <Paging :pager="pager0" end @pagination="pagerUpdate0" />
      <el-row class="grid-content bg-purple-dark mt-5">
        <el-col :span="20" class="bg-purple-title">PO维度数据</el-col>
        <el-col :span="4">
          <el-button
            class="toolbar-icon--button mr-2"
            type="text"
            icon="el-icon-download"
            style="float:left;"
            :loading="exportDownLoading"
            @click="exportDownLoad('pofile',queryFormParams,'应交准交PO表')"
          >下载</el-button>
          <VxeColumnSettings
            v-if="xTableRef"
            ref="VxeColumnSetting"
            :container-ref="xTableRef"
            ref-name="xTable"
            local-key="table-channel"
            class="ml-2"
            @upDate="(val) => $refs.xTable.reloadColumn(val)"
          >
            <el-button
              class="toolbar-icon--button"
              type="text"
              icon="el-icon-s-fold"
              style="float:left;"
              @click="$refs['VxeColumnSetting'].visibleShow()"
            >展示列设置</el-button>
          </VxeColumnSettings>
        </el-col>
      </el-row>

      <vxe-table
        ref="xTable"
        v-loading="poTableLoading"
        border
        max-height="500px"
        resizable
        show-overflow
        highlight-current-row
        align="center"
        :keyboard-config="{isArrow: true}"
        :data="tableData"
      >
        <vxe-table-column field="vendorName" title="供应商" min-width="100" />
        <vxe-table-column field="bg" title="BG" width="60" />
        <vxe-table-column field="bu" title="BU" min-width="60" />
        <vxe-table-column field="seasonStr" title="季节" min-width="80" />
        <vxe-table-column field="category1Code" title="一级类别" min-width="100" />
        <vxe-table-column field="category2Code" title="二级类别" min-width="100" />
        <vxe-table-column field="category3Code" title="三级类别" min-width="100" />
        <vxe-table-column field="style" title="Style" min-width="100" />
        <vxe-table-column field="productPosition" title="产品定位" min-width="100" />
        <vxe-table-column field="purchaseOrderCode" title="采购订单号" min-width="100" />
        <vxe-table-column field="orderTime" title="下单日期" min-width="100" />
        <vxe-table-column field="predictDeliveryDate" title="预计交货日期" min-width="100" />
        <vxe-table-column field="orderLt" title="下单LT" min-width="100" />
        <vxe-table-column field="isFirst" title="是否首单" min-width="100" />
        <vxe-table-column field="prepareOrderFlag" title="是否备料订单" min-width="100" />
        <vxe-table-column field="purchasePersonnel" title="采购员" min-width="100" />
        <vxe-table-column field="operator" title="Operator" min-width="100" />
        <vxe-table-column field="orderTotalNumber" title="订单总数" min-width="100" />
        <vxe-table-column field="otNumber" title="应交数量" min-width="100" />
        <vxe-table-column field="lackNumber" title="缺量数量" min-width="100" />
        <vxe-table-column field="actualCollectDate" title="实际交货日期" min-width="100" />
        <vxe-table-column field="actualCollectNumber" title="实际交货数量" min-width="100" />
        <vxe-table-column field="actualReturnNumber" title="退货数量" min-width="100" />
        <vxe-table-column field="planDeliveryDate" title="应交交货日期" min-width="100" />
        <vxe-table-column field="planDeliveryWeek" title="应交交货周" min-width="100" />
        <vxe-table-column field="isOtByPlanDelivery" title="是否准交" min-width="80" />
        <vxe-table-column field="otddNumber" title="应交准交数量" min-width="100" />
        <vxe-table-column field="otDelayNumber" title="应交延期数量" min-width="100" />
        <vxe-table-column field="otddRatio" title="应交准交率" min-width="100" />
        <vxe-table-column field="delayDays" title="延期天数" min-width="100" />
        <vxe-table-column field="delayDaysRange" title="延期天数归类" min-width="100" />
        <vxe-table-column field="delayReasonType" title="未准交原因类型" min-width="120" />
        <vxe-table-column field="delayReason" title="未准交原因" min-width="100" />
        <vxe-table-column field="substandard" title="LT不达标原因" min-width="100" />
        <vxe-table-column field="purchaseFeedbackDate" title="采购反馈交期" min-width="100" />
        <vxe-table-column field="purchaseFeedbackUpdateTime" title="采购反馈交期更新日期" min-width="170" />
        <vxe-table-column field="purchaseFeedbackWeek" title="采购反馈交期更新周" min-width="150" />
        <vxe-table-column field="assessLt" title="考核LT" min-width="100" />
        <vxe-table-column field="actualLt" title="实际LT" min-width="100" />

      </vxe-table>
      <Paging :pager="pager1" end @pagination="pagerUpdate1" />

      <el-row class="grid-content bg-purple-dark mt-5" type="flex" justify="space-around">
        <el-col :span="20" class="bg-purple-title">明细数据</el-col>
        <el-col :span="6">
          <el-button
            v-permission="'setting'"
            class="toolbar-icon--button mr-2"
            type="text"
            icon="el-icon-setting"
            style="float:left;"
            @click="settingDialog=true"
          >考核设置</el-button>
          <el-button
            class="toolbar-icon--button mr-2"
            type="text"
            icon="el-icon-download"
            style="float:left;"
            :loading="exportDownLoading"
            @click="exportDownLoad('file',queryFormParams,'应交准交明细表')"
          >下载</el-button>
          <VxeColumnSettings
            v-if="yTableRef"
            ref="VxeColumnSettings"
            :container-ref="yTableRef"
            ref-name="yTable"
            local-key="detail-channel"
            style="float:left;"
            @upDate="(val) => $refs.yTable.reloadColumn(val)"
          >
            <el-button
              class="toolbar-icon--button"
              type="text"
              icon="el-icon-s-fold"
              @click="$refs['VxeColumnSettings'].visibleShow()"
            > 展示列设置</el-button>
          </VxeColumnSettings>
        </el-col>
      </el-row>

      <vxe-table
        ref="yTable"
        v-loading="detailTableLoading"
        border
        max-height="500px"
        resizable
        show-overflow
        highlight-current-row
        align="center"
        :keyboard-config="{isArrow: true}"
        :data="detailData"
      >
        <vxe-table-column field="vendorName" title="供应商" min-width="100" />
        <vxe-table-column field="bg" title="BG" width="60" />
        <vxe-table-column field="bu" title="BU" min-width="60" />
        <vxe-table-column field="seasonStr" title="季节" min-width="80" />
        <vxe-table-column field="category1Code" title="一级类别" min-width="100" />
        <vxe-table-column field="category2Code" title="二级类别" min-width="100" />
        <vxe-table-column field="category3Code" title="三级类别" min-width="100" />
        <vxe-table-column field="style" title="Style" min-width="100" />
        <vxe-table-column field="productPosition" title="产品定位" min-width="100" />
        <vxe-table-column field="purchaseOrderCode" title="采购订单号" min-width="100" />
        <vxe-table-column field="orderTime" title="下单日期" min-width="100" />
        <vxe-table-column field="predictDeliveryDate" title="预计交货日期" min-width="100" />
        <vxe-table-column field="orderLt" title="下单LT" min-width="100" />
        <vxe-table-column field="isFirst" title="是否首单" min-width="100" />
        <vxe-table-column field="prepareOrderFlag" title="是否备料订单" min-width="100" />
        <vxe-table-column field="purchasePersonnel" title="采购员" min-width="100" />
        <vxe-table-column field="operator" title="Operator" min-width="100" />
        <vxe-table-column field="orderTotalNumber" title="订单总数" min-width="100" />
        <vxe-table-column field="otNumber" title="应交数量" min-width="100" />
        <vxe-table-column field="actualCollectDate" title="实际交货日期" min-width="100" />
        <vxe-table-column field="actualCollectNumber" title="实际交货数量" min-width="100" />
        <vxe-table-column field="actualReturnNumber" title="退货数量" min-width="100" />
        <vxe-table-column field="planDeliveryDate" title="应交交货日期" min-width="100" />
        <vxe-table-column field="planDeliveryWeek" title="应交交货周" min-width="100" />
        <vxe-table-column field="isOtByPlanDelivery" title="是否准交" min-width="80" />
        <vxe-table-column field="otddNumber" title="应交准交数量" min-width="100" />
        <vxe-table-column field="otDelayNumber" title="应交延期数量" min-width="100" />
        <vxe-table-column field="delayDays" title="延期天数" min-width="100" />
        <vxe-table-column field="delayDaysRange" title="延期天数归类" min-width="100" />
        <vxe-table-column field="delayReasonType" title="未准交原因类型" min-width="120" />
        <vxe-table-column field="delayReason" title="未准交原因" min-width="100" />
        <vxe-table-column field="deliveryRemark" title="交期备注" min-width="100" />
        <vxe-table-column field="substandard" title="LT不达标原因" min-width="100" />
        <vxe-table-column field="substandardNote" title="LT不达标备注" min-width="100" />
        <vxe-table-column field="purchaseFeedbackDate" title="采购反馈交期" min-width="100" />
        <vxe-table-column field="purchaseFeedbackUpdateTime" title="采购反馈交期更新日期" min-width="170" />
        <vxe-table-column field="purchaseFeedbackWeek" title="采购反馈交期更新周" min-width="150" />
        <!-- <vxe-table-column field="leadTime" title="LeadTime" min-width="100" /> -->
        <vxe-table-column field="assessLtFlag" title="是否考核LT" min-width="100" />
        <vxe-table-column field="assessLt" title="考核LT" min-width="100" />
        <vxe-table-column field="actualLt" title="实际LT" min-width="100" />
      </vxe-table>
      <Paging :pager="pager2" end @pagination="pagerUpdate2" />
      <Setting v-model="settingDialog" />
    </div>
  </div>
</template>

<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { queryVendorList, queryStyleList, listBuAndOperator } from '@/api/scm-api'
import { omit } from 'lodash'
import VxeColumnSettings from '@/components/VxeColumnSettings'
import { queryBrandList, queryCategoryList, getPurchasePersonnelList } from '@/api/listSelection'
import { otddCategoryTotal, exportOtddPoList, exportOtddDetailList, pageOtddPo, reportOtddOverview, pageOtddDetail, pageOtddCategory, exportOtddCategoryList } from '@/api/safestock.js'
import { deepClone, formatNum } from '@/utils/index.js'
import Paging from '@/components/Pagination'
import Setting from './components/Setting'
export default {
  name: 'Box',
  components: { VxeColumnSettings, Paging, Setting },
  mixins: [commodityInfoDict],
  data() {
    return {
      settingDialog: false,
      exportDownLoading: false,
      category: '季节',
      options: [{ label: '新老品', value: '1' }, { label: '季节', value: '2' }],
      categoryTableData: [],
      queryCategoryLoading: false,
      arr: ['vendorReviewWeek', 'planDeliveryWeek', 'purchaseFeedbackWeek'],
      PurchasePersonnelList: [],
      styleOptions: [],
      pager0: {
        size: 20,
        current: 1,
        total: 0
      },
      pager1: {
        size: 20,
        current: 1,
        total: 0
      },
      pager2: {
        size: 20,
        current: 1,
        total: 0
      },
      viewDatas: [],
      detailData: [],
      xTableRef: undefined,
      yTableRef: undefined,
      zTableRef: undefined,
      brandOptions: [],
      poTableLoading: false,
      tableData: [],
      queryForm: {
        value1: [],
        value2: [],
        value3: [],
        value4: []

      },
      vendorOptions: [],
      detailTableLoading: false,
      showRow: false,
      queryRules: {},
      categoryList: [],
      groupoptions: [],
      viewArr: [
        { label: '订单总数', val: 'orderTotalNumber' },
        { label: '已交货数量', val: 'actualCollectNumber' },
        { label: '未结数量', val: 'unclearedNumber' },
        { label: '缺量数量', val: 'lackNumber' },
        { label: '应交准交数量', val: 'otddNumber' },
        { label: '应交延期数量', val: 'otDelayNumber' },
        { label: 'OTD', val: 'otd' },
        { label: '实际LT', val: 'actualLt' },
        { label: '考核LT', val: 'assessLt' }
      ],
      saveData: {},
      scrollTop: '',
      cellStyle: {}
    }
  },
  computed: {
    queryFormParams() {
      const { value1, value2, value3, purchaseOrderCode, value4 } = this.queryForm
      const poCode = this.preText(purchaseOrderCode)
      const [placeOrderStartTime, placeOrderEndTime] = value1 || []
      const [collectOrderStartTime, collectOrderEndTime] = value2 || []
      const [planDeliveryOrderStartTime, planDeliveryOrderEndTime] = value3 || []
      const [predictDeliveryStartTime, predictDeliveryEndTime] = value4 || []
      const obj = this.handleData()
      return Object.assign({}, omit(obj, ['value1', 'value2', 'value3', 'value4']), { purchaseOrderCode: poCode }, { placeOrderStartTime, placeOrderEndTime,
        collectOrderStartTime, collectOrderEndTime,
        planDeliveryOrderStartTime, planDeliveryOrderEndTime }, { predictDeliveryStartTime, predictDeliveryEndTime })
    },
    pageOtddDetailParams() {
      return Object.assign(this.pager2, this.queryFormParams)
    },
    pageOtddPoParams() {
      return Object.assign(this.pager1, this.queryFormParams)
    },
    pageOtddCategory() {
      const categoryType = this._getAllCommodityDict('ODTT_CATEGORY_TYPE').find(item => item.label === this.category)?.val
      return Object.assign(this.pager0, this.queryFormParams, { categoryType: categoryType })
    },
    cateParams() {
      return omit(this.pageOtddCategory, ['size', 'current', 'total', 'pages'])
    }
  },
  // watch: {
  //   (val) {
  //     // if you go to the redirect page, do not update the breadcrumbs
  //     alert(111)
  //   }
  // },
  created() {
    this.$nextTick(() => {
      this.xTableRef = this.$refs.xTable
      this.yTableRef = this.$refs.yTable
      this.zTableRef = this.$refs.zTable
    })
  },
  mounted() {
    this._getPurchasePersonnelList()
    this._queryVendorList()
    this.queryClick()
    this._getBrandList()
    this._queryBuList()
    this._queryStyleList()
    this._queryCategoryList()
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.scrollTop = document.documentElement.scrollTop
      this.cellwidth = document.getElementById('box').clientWidth
      // 当页面滑动的距离大于50px时元素置顶，否则位于页面上方
      this.cellStyle = this.scrollTop > 50
        ? `position: fixed; z-index: 100; background-color: #ffffff; top:0;right:30px;width:${this.cellwidth + 12}px`
        : `position: fixed; z-index: 100; background-color:#ffffff; top:90px;right:30px;width:${this.cellwidth + 12}px`
    },
    footerMethod({ columns, data }) {
      const sums = []
      columns.forEach((column, columnIndex) => {
        if (columnIndex === 0) {
          sums.push('汇总')
        } else {
          let sumCell = null
          switch (column.property) {
            case 'netCollectNumber':
              sumCell = this.saveData.netCollectNumber
              break
            case 'otNumber':
              sumCell = this.saveData.otNumber
              break
            case 'otddNumber':
              sumCell = this.saveData.otddNumber
              break
            case 'otDelayNumber':
              sumCell = this.saveData.otDelayNumber
              break
            case 'otd':
              sumCell = this.saveData.otd
              break
            case 'actualLt':
              sumCell = this.saveData.actualLt
              break
            case 'assessLt':
              sumCell = this.saveData.assessLt
              break
            default:
              sumCell = '-'
          }
          sums.push(sumCell)
        }
      })
      return [sums]
    },
    handleCategory() {
      return new Promise((resolve) => {
        otddCategoryTotal(omit(this.pageOtddCategory, ['size', 'current', 'total', 'pages'])).then(res => {
          const { datas = [] } = res
          this.saveData = datas
          resolve()
        })
      }).then(() => {
        return new Promise((resolve) => {
          pageOtddCategory(this.pageOtddCategory).then((res) => {
            const { datas: { records, pager }, code } = res
            if (code === 0) {
              this.categoryTableData = records
              this.pager0 = pager
              resolve()
            }
          })
        })
      })
    },
    async _getPurchasePersonnelList() {
      const { datas } = await getPurchasePersonnelList()
      this.PurchasePersonnelList = datas
    },
    handleData() {
      const obj = deepClone(this.queryForm)

      for (const i in obj) {
        const arr = ['isFirst', 'value1', 'value2', 'value3']
        if (!arr.includes(i)) {
          obj[i] = String(obj[i]) || ''
        }
      }
      return obj
    },
    async _queryBuList() {
      const { datas: { list }} = await listBuAndOperator()
      this.groupoptions = list
    },
    async _queryCategoryList() {
      const { datas } = await queryCategoryList({ categoryLevel: 3 })
      this.categoryList = datas
    },
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    pagerUpdate0(val) {
      this.pager0 = val
      this.handleCategory()
    },
    pagerUpdate1(val) {
      this.pager1 = val
      this.queryTableData()
    },
    pagerUpdate2(val) {
      this.pager2 = val
      this.queryDetailTableData()
    },

    async _getBrandList() {
      const { datas } = await queryBrandList()
      this.brandOptions = datas
    },
    async exportDownLoad(flag, params, fileName) {
      try {
        const api = flag === 'pofile' ? exportOtddPoList : flag === 'file' ? exportOtddDetailList : exportOtddCategoryList
        this.exportDownLoading = true
        await api(params)
        this.$notify({
          message: '操作成功，请前往文件导出界面下载',
          type: 'success'
        })
      } finally {
        this.exportDownLoading = false
      }
    },
    preText(pretext) {
      // return pretext && pretext.replace(/\r\n/g, '<br/>').replace(/\n/g, ',')
      const arr = []
      const array = pretext && pretext.replace(/\r\n/g, '<br/>').replace(/\n/g, ',').replace(/\s/g, '&nbsp;').split(',') || []
      array.map(item => {
        if (item) {
          arr.push(item)
        }
      })
      if (arr.length > 50) {
        this.$message({
          message: '最大输入50个采购单号',
          type: 'warning'
        })
        return
      }
      return arr.join(',')
    },
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    resetClick() {
      this.queryForm = this.$options.data().queryForm
      this.queryClick()
    },
    async queryDetailTableData() {
      // 应交准交明细pageOtddDetail
      try {
        this.detailTableLoading = true
        const { code, datas: { records, pager }} = await pageOtddDetail(this.pageOtddDetailParams)
        if (code === 0) {
          this.pager2 = pager
          const fieldNullArr = ['otNumber', 'otddNumber', 'otDelayNumber', 'delayDays']
          const fieldArr = ['actualCollectDate', 'planDeliveryWeek', 'planDeliveryDate', 'delayDaysRange', 'delayReasonType', 'delayReason']
          this.detailData = records.map(item => {
            for (const i in item) {
              if (/^[0-9]+.?[0-9]*/.test(item[i]) && !this.arr.includes(i)) {
                item[i] = formatNum(item[i])
              }
              // 处理Thunder之前的代码
              if (fieldArr.includes(i)) {
                item[i] = item[i] || '/'
              }
              if (fieldNullArr.includes(i)) {
                item[i] = item[i] ? item[i] : item[i] === null ? '/' : ''
              }
              if (['isOtByPlanDelivery', 'isFirst', 'prepareOrderFlag', 'assessLtFlag'].includes(i)) {
                item[i] = item[i] === true ? '是' : item[i] === null ? '/' : '否'
              }
              if (i === 'substandard') {
                item[i] = item[i] !== 'null' ? item[i] : ''
              }
            }
            return item
          })
        }
      } finally {
        this.detailTableLoading = false
      }
    },
    async queryTableData() {
      try {
        this.poTableLoading = true
        const { code, datas: { records, pager }} = await pageOtddPo(this.pageOtddPoParams)
        if (code === 0) {
          this.pager1 = pager
          const fieldNullArr = ['otNumber', 'planDeliveryWeek', 'otddNumber', 'otDelayNumber', 'otddRatio', 'delayDays']
          const fieldArr = ['actualCollectDate', 'planDeliveryDate', 'delayDaysRange', 'delayReasonType', 'delayReason']
          this.tableData = records.map(item => {
            for (const i in item) {
              if (/^[0-9]+.?[0-9]*/.test(item[i]) && !this.arr.includes(i)) {
                item[i] = formatNum(item[i])
              }
              if (['otddRatio', 'reviewDdRatio'].includes(i)) {
                item[i] = !item[i] ? item[i] : item[i] + '' === '1' ? '100%' : (item[i] * 100).toFixed(2) + '%'
              }
              // 处理Thunder之前的代码
              if (fieldArr.includes(i)) {
                item[i] = item[i] || '/'
              }
              if (fieldNullArr.includes(i)) {
                item[i] = item[i] ? item[i] : item[i] === null ? '/' : ''
              }
              if (['isOtByPlanDelivery', 'isFirst', 'prepareOrderFlag'].includes(i)) {
                item[i] = item[i] === true ? '是' : item[i] === null ? '/' : '否'
              }
              if (i === 'substandard') {
                item[i] = item[i] !== 'null' ? item[i] : ''
              }
            }
            return item
          })
        }
      } finally {
        this.poTableLoading = false
      }
    },
    async queryClick() {
      this.viewDatas = []
      this.pager0.current = 1
      this.pager1.current = 1
      this.pager2.current = 1
      const { code, datas } = await reportOtddOverview(this.queryFormParams)
      if (code === 0) {
        const obj = omit(datas, ['cancelNumber', 'returnNumber', 'reviewDdNumber', 'reviewDelayNumber'])
        for (const i in obj) {
          const label = this.viewArr.find(item => item.val === i)?.label
          label && this.viewDatas.push({ label, value: formatNum(obj[i]) })
        }
      }
      this.queryTableData()
      this.queryDetailTableData()
      this.handleCategory()
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-range-input {
  width: 90px !important;
}
/deep/.el-date-editor {
  width: 100%;
}
.view-data {
  flex: 1;
  border-radius: 10px;
  border: 1px solid rgba(121, 121, 121, 1);
  margin-right: 10px;
  width: 90px;
  // height: 70px;
  padding:0 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 40px;
  font-size:15px;
}
/deep/ .line {
  height: 450px;
  width: 100%;
  padding: 0 15px;
}
// .seperate
::v-deep .seperate .vxe-table--header thead tr:first-of-type th:first-of-type {
  background: #f8f8f9;
  border-right: 1px solid #e6ebf5 !important;
}
::v-deep
  .seperate
  .vxe-table--header
  thead
  tr:first-of-type
  th:first-of-type:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 168px; /*这⾥需要⾃⼰调整，根据td的宽度和⾼度*/
  top: 0;
  left: 0;
  background-color: grey;
  opacity: 0.3;
  display: block;
  transform: rotate(-62.2deg); /*这⾥需要⾃⼰调整，根据线的位置*/
  transform-origin: top;
}
// ::v-deep .vxe-table--header thead tr:last-of-type th:first-of-type:before {
//   content: '';
//   position: absolute;
//   width: 1px;
//   height: 0px;
//   bottom: 0;
//   right: 0;
//   background-color: grey;
//   opacity: 0.3;
//   display: block;
//   transform: rotate(-61.3deg);
//   transform-origin: bottom;
// }
</style>
